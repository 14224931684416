import axios, { AxiosError, AxiosResponse } from "axios";

type ResponseData = {
  message: string;
  statusCode: number;
};

const api = ({ headers = {}, params = {} } = {}) => {
  const base = "https://dev-sales-api-user-and-auth.asdf.id";
  const instance = axios.create({
    baseURL: `${base}/v1/auth`,
    headers: {
      "Content-type": "application/json",
      ...headers,
    },
    params,
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (
        error.response?.status === 401 ||
        error.response?.status === 500 ||
        (error.response?.data as ResponseData).message === "jwt malformed"
      ) {
        window.dispatchEvent(new CustomEvent("user:expired"));
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

type ReLogin = { jwt: string };
const reLogin = (body: ReLogin) => {
  return api().post("/login", body);
};

const AuthAPIs = { reLogin };

export default AuthAPIs;
