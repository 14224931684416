import {
  navigateToUrl,
  registerApplication,
  SingleSpaCustomEventDetail,
  start,
} from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import AuthServices from "./services/auth.service";

const forceLogoutURL = "https://dev-app.asdf.id/logout";

/**
 * Attempt to relogin with the given token.
 * If successful, will set the user data to local storage and return true.
 * If failed, will return the error object.
 *
 * @param {string} jwt - The JWT token to use for relogin.
 */
const reLogin = async (jwt: string) => {
  try {
    const response = await AuthServices.reLogin({ jwt });
    if (response?.data) {
      const userData = response.data.data;
      localStorage.setItem("user", JSON.stringify(userData));
      return true;
    }
    return false;
  } catch (error) {
    return error;
  }
};

/**
 * Attempt to relogin using the jwt token stored in local storage.
 * If relogin fails, will force logout the user.
 * If relogin succeeds, will trigger an event to update the navbar's user profile picture.
 */
const reLoginFromStorage = async () => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  if (user.token) {
    const { token } = user;
    const loggedIn = await reLogin(token);
    if (loggedIn) {
      // Custom event for triggering navbar to update user's profile picture
      window.dispatchEvent(new CustomEvent("updateUserData"));
    } else {
      // Logout while reLogin failed
      forceLogout();
    }
  } else {
    forceLogout();
  }
};

/**
 * Remove the protocol and domain from a URL, leaving only the path part.
 *
 * @example getPathName('https://example.com/path') => '/path'
 * @param {string} url - The URL to process
 * @return {string}
 */
const getPathName = (url: string): string => {
  return url.replace(/^https?:\/\/[^/]+/, "");
};

const forceLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = forceLogoutURL;
};

const beforeRoutingEventHandler = async (
  evt: CustomEvent<SingleSpaCustomEventDetail>
): Promise<void> => {
  if (evt.detail.newUrl.includes("logout")) return; // Escape from manageRoute while logout

  const splittedUrlByQuery = evt.detail.newUrl.split("?u=");
  let userQuery = splittedUrlByQuery?.length > 1 ? splittedUrlByQuery[1] : null;

  if (userQuery) {
    localStorage.setItem("user", atob(userQuery));

    const destination = "dashboard";
    const BASE_URL = "https://dev-crm.asdf.id";

    // Redirect to destination (remove query ?u) after successfully set user data from select module in portal
    window.location.href = `${BASE_URL}/${destination}`;
  } else {
    const userFromStorage = JSON.parse(localStorage.getItem("user") ?? "{}");
    if (userFromStorage.token) {
      const pathName = getPathName(evt.detail.newUrl);
      if (!pathName || pathName === "/") {
        navigateToUrl("/dashboard");
      }
    } else {
      forceLogout();
    }
  }
};

(function manageRoute() {
  window.addEventListener(
    "single-spa:before-routing-event",
    beforeRoutingEventHandler
  );
  reLoginFromStorage();
})();

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
  const isSidebarLeft = app.name === "@qtera/sales-mfe-sidebar-left";
  const isNavbar = app.name === "@qtera/sales-mfe-navbar";

  if (isSidebarLeft || isNavbar) {
    registerApplication({
      name: app.name,
      app: app.app,
      activeWhen: (route) => !route.pathname.includes("print"),
      customProps: app.customProps,
    });
  } else {
    registerApplication(app);
  }
});
layoutEngine.activate();

start();
